import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../src/components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoBox from '../components/global/info-box/InfoBox';
import InfoboxTwo from '../components/solution-page-template/Infobox';
import Customers from '../components/global/customers/Customers';
import * as S from '../components/solution-page-template/styles';
import CTA from '../components/global/CTA/CTA';
import Snippet from '../components/global/snippet/Snippet';
import { ImageHolder } from '../components/home-page/about-chatbots/styles';
import StatsBox from '../components/customer-page-template/stats-box/StatsBox';
import FeaturedCustomers from '../components/home-page/featured-customers/FeaturedCustomers';
import CustomerStory from '../components/global/CustomerStory';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import SEO from '../components/global/seo';

const SolutionPage = ({ data }) => {
	const {
		section1Title,
		section1Subtitle,
		section1Image,
		section1Button,
		section2Title,
		section2Images,
		section3Title,
		section3Image,
		section3Content,
		section4Title,
		section4Image,
		section4Content,
		section5Image,
		section5Logo,
		section5Text,
		section5Name,
		section5Title,
		section5Slug,
		section6Title,
		section6Image,
		section6Content,
		section7Title,
		section7Image,
		section7Content,
		section8Title,
		section8Subtitle,
		section9Title,
		section9Images,
		section10Title,
		section10Stats,
		section11Title,
		section12Title,
		section12Subtitle,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
	} = data.contentfulSolutions;
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne>
				<S.HeroWrapper>
					<InfoBox
						image={section1Image}
						title={section1Title}
						subtitle={section1Subtitle}
						hideImage="true"
						justifyContent="center"
						buttonText={section1Button?.linkText}
						buttonLink={section1Button?.link}
						isHeroHeader
					/>
				</S.HeroWrapper>
			</WrapperOne>
			<Customers
				section2Images={section2Images}
				section2Title={section2Title}
			/>
			<WrapperOne>
				<S.SectionWrapper>
					<InfoboxTwo
						image={section3Image}
						title={section3Title}
						invert={true}
						content={section3Content}
					/>
				</S.SectionWrapper>
				<S.SectionWrapper>
					<InfoboxTwo
						image={section4Image}
						title={section4Title}
						content={section4Content}
					/>
				</S.SectionWrapper>
				<S.CustomerStoryWrapper>
					<CustomerStory
						customerStoryImage={section5Image}
						customerStoryLogo={section5Logo}
						customerStoryText={section5Text}
						customerName={section5Name}
						customerTitle={section5Title}
						cusomterSlug={section5Slug}
					></CustomerStory>
				</S.CustomerStoryWrapper>
				<S.SectionWrapper>
					<InfoboxTwo
						image={section6Image}
						title={section6Title}
						invert={true}
						content={section6Content}
					/>
				</S.SectionWrapper>
				<S.SectionWrapper>
					<InfoboxTwo
						image={section7Image}
						title={section7Title}
						content={section7Content}
					/>
				</S.SectionWrapper>
				<S.SectionWrapper>
					<S.CTAWrapper>
						<CTA title={section8Title} subtitle={section8Subtitle} />
					</S.CTAWrapper>
				</S.SectionWrapper>
				<S.SectionWrapper>
					<S.ContentWrapper>
						<SectionTextBox title={section9Title} />
					</S.ContentWrapper>
					<ImageHolder>
						{section9Images.map(info => (
							<Snippet
								url={info.file.url}
								title={info.title}
								subtitle={info.description}
							/>
						))}
					</ImageHolder>
				</S.SectionWrapper>
				<S.SectionWrapper>
					<S.SectionTextBoxWrapper>
						<SectionTextBox title={section10Title} />
					</S.SectionTextBoxWrapper>
				</S.SectionWrapper>

				<S.StatsBoxWrapper>
					{section10Stats && <StatsBox data={section10Stats} />}
				</S.StatsBoxWrapper>
				<S.SectionWrapper>
					<S.FeaturedCustomersWrapper>
						<FeaturedCustomers
							section10Title={section11Title}
							customers={data.allContentfulCustomers.nodes}
						/>
					</S.FeaturedCustomersWrapper>
				</S.SectionWrapper>
				<S.SectionWrapper>
					<S.CTAWrapper2>
						<CTA title={section12Title} subtitle={section12Subtitle} />
					</S.CTAWrapper2>
				</S.SectionWrapper>
			</WrapperOne>
		</Layout>
	);
};

export const query = graphql`
	query($slug: String, $relatedCustomerSlug: [String]) {
		contentfulSolutions(slug: { eq: $slug }) {
			section1Title
			section1Subtitle
			section1Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section1Button {
				linkText
				link
			}
			section2Title
			section2Images {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section3Title
			section3Subtitle {
				section3Subtitle
			}
			section3Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section3Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section4Title
			section4Subtitle {
				section4Subtitle
			}
			section4Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section4Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section5Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section5Logo {
				fixed(width: 125) {
					...GatsbyContentfulFixed
				}
				title
			}
			section5Text
			section5Name
			section5Title
			section5Slug
			section6Title
			section6Subtitle {
				section6Subtitle
			}
			section6Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section6Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section7Title
			section7Subtitle {
				section7Subtitle
			}
			section7Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section7Content {
				subtitle {
					type
					content
				}
				link {
					linkURL
					linkText
				}
			}
			section8Title
			section8Subtitle
			section9Title
			section9Images {
				file {
					url
				}
				title
				description
			}
			section10Title
			section10Stats {
				content {
					content2
					content1
				}
			}
			section11Title
			section12Title
			section12Subtitle
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoJsonSchema {
				internal {
					content
				}
			}
		}
		allContentfulCustomers(filter: { slug: { in: $relatedCustomerSlug } }) {
			nodes {
				title
				slug
				cardImage {
					fluid(maxWidth: 234, maxHeight: 234) {
						...GatsbyContentfulFluid
					}
					title
				}
				cardLogo {
					fixed(width: 200) {
						...GatsbyContentfulFixed
					}
					title
				}
			}
		}
	}
`;
export default SolutionPage;
