import styled from 'styled-components';
import { mediaQuery } from '../../../utils/helpers';

export const HeroWrapper = styled.div`
	img{
		max-height: unset !important;
	}
`

export const SectionWrapper = styled.div`
	margin-top: 50px;
	${mediaQuery.laptopL`
		margin-top: 50px;
    `}
	${mediaQuery.mobileXL`
        margin-top: 20px;
    `}
`;

export const CustomerStoryWrapper = styled.div`
	margin-top: 50px;
	padding-top: 150px;
	${mediaQuery.laptopL`
		margin-top: 50px;
		padding-top:120px;
    `}
	${mediaQuery.tabletXS`
			margin:0;
        	margin-top: 50px;
			padding-top:100px;
    `}
	${mediaQuery.mobileM`
			margin:0;
        	margin-top: 20px;
			padding-top:100px;
    `}
`;

export const ContentWrapper = styled.div`
	margin-top: 50px;
	padding-top: 120px;
	margin-bottom: 50px;
	${mediaQuery.laptopL`
		padding-top: 70px;
		margin-bottom: 50px;
    `}

	${mediaQuery.mobileM`
        	margin-top: 20px;
			padding-top: 50px;
    `}
`;

export const CTAWrapper = styled.div`
	padding-top: 70px;
	${mediaQuery.mobileXL`
       	padding-top: 40px;
    `}
`;

export const SectionTextBoxWrapper = styled.div`
	margin-top: 50px;
	padding-top: 140px;
	margin-bottom: 25px;
	${mediaQuery.laptopL`
		margin-top: 50px;
		padding-top: 60px;
		margin-bottom: 35px;
    `}
	${mediaQuery.mobileM`
		margin-top: 50px;
		padding-top:30px;
		margin-bottom: 25px;
    `}
`;

export const StatsBoxWrapper = styled.div`
	padding-bottom: 110px;
	${mediaQuery.laptopL`
		padding-bottom:50px;
    `}
`;

export const FeaturedCustomersWrapper = styled.div`
	padding-top: 80px;
	${mediaQuery.laptopL`
		padding-top:20px;
    `}

	${mediaQuery.mobileM`
		padding-top: 60px;
    `}
`;

export const CTAWrapper2 = styled.div`
	padding-top: 260px;
	margin-bottom: 20px;
	${mediaQuery.mobileXL`
       	padding-top: 55px;
    `}
`;
